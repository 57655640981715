.about-container{
    height: 100%;
    min-height: 875px;
    background-color: #FFFFFF;
    color: #202020;
    /* background-color: #FFFFFF;
    color: #202020; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
}
.profilepic{
    border-radius: 50%;
    width: 170px;
    height: 170px;
    border: 2.5px solid #202020;
    margin: 10px;
    float: left;
    shape-outside: circle();
}
.profile-container{
    height: 100%;
    border-bottom: 2.5px solid #202020;
}
.about-horizontal-container{
    display: flex;
    flex-direction: row;
}
.education-container{
    margin-top: 50px;
    width: 50%;
    padding: 2px;
    word-wrap: break-word;
}
.education-container b{
    font-size: 18px;
    text-decoration: underline;
}
.resume-button{
    background-color: #202020;
    color: #FFFFFF;
    margin-top: 50px;
    border-radius: 12px;
    padding: 5px;
    margin-bottom: 10px;
}
.resume-button:hover{
    box-shadow: 0px 1px 5px #202020;
}