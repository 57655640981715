@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Lato', sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* old */
.header-component-scroll{
    font-family: 'Lato', sans-serif;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    width: 100%;
    height: 45px;
    border-bottom: #303030 solid 2px;
    z-index: 900;
    background: linear-gradient( #202020, #292929);
    justify-content: center;
    align-items: center;
}

/* new */
.header-component{
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: center;
    align-items: center;
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.scroll_links{
    color:white;
    cursor: pointer;
    padding: 2px;
    letter-spacing: 1px;
    text-decoration: none;
    text-shadow: 0px 2px 10px black;
}

.scroll_links:hover{
    color: white;
    text-shadow: 0px 1px 10px rgb(146, 146, 146);
}
.landing-page{
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    min-height: 100vh;
    background-image: url(/static/media/wallpaper.7aaa23fa.svg);
    background-repeat: no-repeat ;
    background-size: cover;
    background-position: center;
    width: 100%;

}
.landing-container{
    padding: 45px;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Lato', sans-serif;
}

.bio{
    border-bottom: 1px solid rgba(255, 255, 255, 0.692);
    padding: 5px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.landing-pic{
    border: solid white 2px;
    width: 300px;
    border-radius: 50%;
}

 /* fontawesome icons */
.links{
    width: 300px;
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
}

.contacts{
    color: white;
}
.contacts:hover{
    color: rgb(230, 230, 230);
    -webkit-filter: drop-shadow(0px 1px 7px rgba(233, 233, 233, 0.63));
            filter: drop-shadow(0px 1px 7px rgba(233, 233, 233, 0.63));
}
.about-container{
    height: 100%;
    min-height: 875px;
    background-color: #FFFFFF;
    color: #202020;
    /* background-color: #FFFFFF;
    color: #202020; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
}
.profilepic{
    border-radius: 50%;
    width: 170px;
    height: 170px;
    border: 2.5px solid #202020;
    margin: 10px;
    float: left;
    shape-outside: circle();
}
.profile-container{
    height: 100%;
    border-bottom: 2.5px solid #202020;
}
.about-horizontal-container{
    display: flex;
    flex-direction: row;
}
.education-container{
    margin-top: 50px;
    width: 50%;
    padding: 2px;
    word-wrap: break-word;
}
.education-container b{
    font-size: 18px;
    text-decoration: underline;
}
.resume-button{
    background-color: #202020;
    color: #FFFFFF;
    margin-top: 50px;
    border-radius: 12px;
    padding: 5px;
    margin-bottom: 10px;
}
.resume-button:hover{
    box-shadow: 0px 1px 5px #202020;
}
/* Skills Component */
.skills-page{
    height: 100%;
    min-height: 875px;
    background-color: #202020;
    color: #FFFFFF;
    /* background-color: #FFFFFF; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
}
.skills-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}
.progress-bars{
    width: 100%;
    height: 100%;
}
.barmargin{
    margin-top: 15px;
}

/* Project */
.projects-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5px;
}
.project-pic-wrapper{
    width: 200px;
    height: 200px;
    margin: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    opacity: 1.0;
}
.project-pic-wrapper:hover{
    border-radius: 29px;
    box-shadow: 0px 1px 10px white;
    opacity: .70;
}
.project-logos{
    width: 100%;
    border-radius: 29px;
    border: 2px solid white;
}
.modals{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 85%;
    height: 90%;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
    overflow-y: scroll;
    box-shadow: 0px 0px 15px rgb(80, 80, 80);
    background-color: white;
    font-family: 'Lato', sans-serif;
}
.project-titlebar{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}
.project-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 700;
}
.project-example{
    width: 75%;
    box-shadow: 0px 0px 10px rgb(54, 54, 54);
    margin-top: 10px;
    margin-bottom: 15px;
    border: 2px solid black;
}

/* FontAwesome Icon */
.exiticon{
    color: black;
}
.exiticon:hover{
    cursor: pointer;
    color: rgb(216, 36, 36);

}

.footer-container-closed{
    width: 100%;
    background-color: #FFFFFF;
    color: #202020;
    /* background: linear-gradient( #202020, #292929); */
    font-family: 'Lato', sans-serif;
}
.footer-container-open{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color: #FFFFFF;
    color: #202020;
    /* box-shadow: 0px -5px 3px rgba(36, 36, 36, 0.26); */
    border-top: 1px solid rgb(0, 0, 0);
    font-family: 'Lato', sans-serif;
}
.cspace{
    background-color: transparent;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 10px;

}
.info-item{
    font-size: 18px;
    list-style-type: none;
}
.testicon{
    color: #202020;
}
.testicon:hover{
    cursor: pointer;
    opacity: .75;
}

.email-container{
    border-top: solid #202020;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.email-form{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Lato', sans-serif;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    background-color: #202020;
    padding: 10px;
    border-radius: 10px;
}
.email-button{
    margin-left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    background-color: #FFFFFF;
    color: #202020;
    margin-top: 5px;
    width: 40%;
    border-radius: 12px;
    padding: 2px;
}
.email-button:hover{
    box-shadow: 0px 1px 5px white;
}

