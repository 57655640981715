/* Skills Component */
.skills-page{
    height: 100%;
    min-height: 875px;
    background-color: #202020;
    color: #FFFFFF;
    /* background-color: #FFFFFF; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
}
.skills-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}
.progress-bars{
    width: 100%;
    height: 100%;
}
.barmargin{
    margin-top: 15px;
}

/* Project */
.projects-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5px;
}
.project-pic-wrapper{
    width: 200px;
    height: 200px;
    margin: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    opacity: 1.0;
}
.project-pic-wrapper:hover{
    border-radius: 29px;
    box-shadow: 0px 1px 10px white;
    opacity: .70;
}
.project-logos{
    width: 100%;
    border-radius: 29px;
    border: 2px solid white;
}
.modals{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 85%;
    height: 90%;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
    overflow-y: scroll;
    box-shadow: 0px 0px 15px rgb(80, 80, 80);
    background-color: white;
    font-family: 'Lato', sans-serif;
}
.project-titlebar{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}
.project-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 700;
}
.project-example{
    width: 75%;
    box-shadow: 0px 0px 10px rgb(54, 54, 54);
    margin-top: 10px;
    margin-bottom: 15px;
    border: 2px solid black;
}

/* FontAwesome Icon */
.exiticon{
    color: black;
}
.exiticon:hover{
    cursor: pointer;
    color: rgb(216, 36, 36);

}
