@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

/* old */
.header-component-scroll{
    font-family: 'Lato', sans-serif;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    width: 100%;
    height: 45px;
    border-bottom: #303030 solid 2px;
    z-index: 900;
    background: linear-gradient( #202020, #292929);
    justify-content: center;
    align-items: center;
}

/* new */
.header-component{
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: center;
    align-items: center;
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.scroll_links{
    color:white;
    cursor: pointer;
    padding: 2px;
    letter-spacing: 1px;
    text-decoration: none;
    text-shadow: 0px 2px 10px black;
}

.scroll_links:hover{
    color: white;
    text-shadow: 0px 1px 10px rgb(146, 146, 146);
}