.footer-container-closed{
    width: 100%;
    background-color: #FFFFFF;
    color: #202020;
    /* background: linear-gradient( #202020, #292929); */
    font-family: 'Lato', sans-serif;
}
.footer-container-open{
    height: fit-content;
    width: 100%;
    background-color: #FFFFFF;
    color: #202020;
    /* box-shadow: 0px -5px 3px rgba(36, 36, 36, 0.26); */
    border-top: 1px solid rgb(0, 0, 0);
    font-family: 'Lato', sans-serif;
}
.cspace{
    background-color: transparent;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 10px;

}
.info-item{
    font-size: 18px;
    list-style-type: none;
}
.testicon{
    color: #202020;
}
.testicon:hover{
    cursor: pointer;
    opacity: .75;
}

.email-container{
    border-top: solid #202020;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.email-form{
    width: fit-content;
    font-family: 'Lato', sans-serif;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    background-color: #202020;
    padding: 10px;
    border-radius: 10px;
}
.email-button{
    margin-left: 50%;
    transform: translate(-50%);
    background-color: #FFFFFF;
    color: #202020;
    margin-top: 5px;
    width: 40%;
    border-radius: 12px;
    padding: 2px;
}
.email-button:hover{
    box-shadow: 0px 1px 5px white;
}
