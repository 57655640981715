.landing-page{
    max-height: fit-content;
    min-height: 100vh;
    background-image: url('../assets/about/wallpaper.svg');
    background-repeat: no-repeat ;
    background-size: cover;
    background-position: center;
    width: 100%;

}
.landing-container{
    padding: 45px;
    max-height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Lato', sans-serif;
}

.bio{
    border-bottom: 1px solid rgba(255, 255, 255, 0.692);
    padding: 5px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.landing-pic{
    border: solid white 2px;
    width: 300px;
    border-radius: 50%;
}

 /* fontawesome icons */
.links{
    width: 300px;
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
}

.contacts{
    color: white;
}
.contacts:hover{
    color: rgb(230, 230, 230);
    filter: drop-shadow(0px 1px 7px rgba(233, 233, 233, 0.63));
}